import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'

import Hero from '../../../components/dai/Hero'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import Quote from '../../../components/dai/Quote'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import { Button } from '../../../components/dai/Button'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'
import TextLink from '../../../components/common/TextLink'

import Speaker from '../../../images/dai/icons/assets/speaker.svg'
import Hide from '../../../images/dai/icons/assets/hide.svg'
import Keyboard from '../../../images/dai/icons/assets/keyboard.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

export default function Government() {
  return (
    <LayoutDAI
      freeAudit
      showIndustryReports
      pagePath={{
        parent: 'industry-reports',
        child: 'government',
        freeAuditHeading: 'Find out how your site compares to other government sites.',
      }}
    >
      <SEO
        title="Government Accessibility | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/industry-reports/government/"
        desc="Our expert review of key government sites found another 10 accessibility barriers across the pages tested, which included account profiles and contact pages."
        bannerUrl="https://images.prismic.io/audioeye-web/707864c1-88bc-4a0b-a516-7fba293501f6_government.jpg?auto=compress,format"
        imageAlt="Stylized billboard for the Government industry with a subheading that reads “No Tab Order”"
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/industry-reports/government/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/government.png"
            alt="Stylized billboard for the Government industry with a subheading that reads “No Tab Order”"
          />
        }
        backgroundColor="orange200"
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          Government
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt>
          Government sites may be for the people, but they don’t work for all.
        </Heading1>
        <Space height={32} />
        <Text>
          When we asked several members of the disability community about their experience with state and federal
          government websites, each one shared a similar story.
        </Text>
        <Space height={32} />
        <Text>
          Government sites tend to be less accessible than the private industry (or, as one member of the disability
          community put it, “atrocious across the board”), which can be especially problematic because there usually
          isn’t an alternative. If they need to file a police report or register to vote, there’s only one place to go.
          And if that site isn’t accessible, they’re stuck.
        </Text>
      </Hero>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“I had to go on the immigration website about a year ago. The first page was pretty accessible. But the second I needed to put in a case number, check statuses or change addresses, it broke down pretty quickly.”"
          author="Christina Campbell"
          title="AudioEye A11iance Member"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          EXPERT AUDITS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Breaking down the most significant barriers on the top government sites.
        </Heading2>
        <Space height={32} />
        <Text>
          After reviewing the top government sites for things like healthcare and retirement benefits, our expert
          testers noted that keyboard accessibility issues made it difficult to look up information, log into personal
          accounts, or submit official documents.
        </Text>
        <Space height={32} />
        <Text>
          On average, our testers found another{' '}
          <BoldText>
            10 accessibility barriers across the pages tested, which included account profiles and contact pages
          </BoldText>{' '}
          — including three consistent barriers that impacted their ability to use government services.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns orderedList>
          <Column size={12} listItem>
            <IconCard noCard>
              <Speaker />
              <Space height={16} />
              <Text heading3 className="asH4">
                1. No audible announcement when forms were missing required information.
              </Text>
              <Space height={16} />
              <Text>
                Our experts found a number of forms that did not audibly announce when required information was missing,
                which can leave non-sighted customers unaware that their form was not submitted. Instead, the page
                simply reloaded with no announcement that a form was missing required information.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/error-identification.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.1: Error Identification;"
                  anchorClassName="unstyled-link dai-link"
                />
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.2: Labels or Instructions"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <Hide />
              <Space height={16} />
              <Text heading3 className="asH4">
                2. Unclear labels made it difficult for non-sighted users to navigate between pages.
              </Text>
              <Space height={16} />
              <Text>
                Our experts encountered numerous links that lacked descriptive labels to aid in navigation (for example:
                a series of consecutive links were all labeled “learn more,” instead of informing users that one link
                pointed to small business coverage and another to Medicaid coverage.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value;"
                  anchorClassName="unstyled-link dai-link"
                />
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
                  target="_blank"
                  rel="noreferrer"
                  text="2.4.4: Link Purpose (In Context)"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <Keyboard />
              <Space height={16} />
              <Text heading3 className="asH4">
                3. Keyboard-only navigation was a consistent challenge across sites.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted a number of keyboard accessibility issues that can impact site navigation, such as an
                inability to use arrow keys to tab between page sections (the most widely used method of navigation for
                screen reader users).
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html"
                  target="_blank"
                  rel="noreferrer"
                  text="2.1.1: Keyboard Accessibility"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“For some government websites, you can tell  they’ve put a lot of work into accessibility. And for others, it’s like they just threw things together and whatever happens, happens.”"
          author="Jennifer Piening"
          title="AudioEye A11iance Member"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          AUTOMATED SCAN RESULTS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Most common issues, government sites.
        </Heading2>
        <Space height={32} />
        <Text>
          Government sites struggled in a number of key areas that made it difficult for people with disabilities to
          access online services, obtain information, and fill out required forms and information.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/square-sign-63.png"
                alt="Pie chart representing 63%. The pie chart is in the shape of a construction road sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              63% of government pages had at least one interactive element that could not be manipulated by keyboard.
            </Text>
            <Space height={24} />
            <Text>
              Many of these barriers affected user actions like tabbing between form fields, closing pop-up windows, or
              pressing ‘Submit’ buttons.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                target="_blank"
                rel="noreferrer"
                text="1.1.1: Non-Text Content"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/square-sign-62.png"
                alt="Pie chart representing 62%. The pie chart is in the shape of a construction road sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              62% of government pages had at least one image with missing or inadequate alt text.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive alt text on product photos and other graphics, people with visual and cognitive
              disabilities can have a hard time understanding what an image is supposed to illustrate.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                target="_blank"
                rel="noreferrer"
                text="1.1.1: Non-Text Content"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/square-sign-48.png"
                alt="Pie chart representing 48%. The pie chart is in the shape of a construction road sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              48% of government pages with a form had at least one field that was not labeled.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive field labels, it can be difficult for people with visual and cognitive impairments to
              obtain government services, such as registering to vote, applying for benefits programs, or filing taxes.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                target="_blank"
                rel="noreferrer"
                text="3.3.2: Labels or Instructions"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“In general, government sites lag behind the private industry. Even making a required payment for my business can be hard.”"
          author="TJ Olsen"
          title="AudioEye A11iance Member and QA Tester"
        />
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="lg" backgroundColor="orange300">
        <Columns>
          <Column size={16} tabletSize={14}>
            <Text heading3 className="asH4">
              Discover how close your government site is to WCAG conformance.
            </Text>
            <Space height={32} />
            <Text>
              Uncover accessibility barriers that cannot be found by automation alone with an Expert Audit of your most
              important pages.
            </Text>
          </Column>
          <Column size={8} tabletSize={10} justifyCenter alignItemsEnd>
            <Button
              text="Schedule Expert Audit"
              to="/digital-accessibility-index/industry-reports/government/#expert-audit"
              size="small"
              tag="DAI Page Content"
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/industry-reports/insurance/"
        previousPageText="Insurance"
        nextPageLink="/digital-accessibility-index/methodology/"
        nextPageText="Methodology"
      />
    </LayoutDAI>
  )
}
